import React from "react";

import FadeLink from "./fadeLink";
import Logo from "./logo";

import { footer, active } from "./footer.module.scss";

export default function Footer({ noLogo }) {
  return (
    <div className={footer}>
      {!noLogo && <Logo />}
      <FadeLink to="/about/" activeClassName={active}>
        About
      </FadeLink>
      <FadeLink to="/contact/" activeClassName={active}>
        Contact
      </FadeLink>
      <FadeLink to="/login/" activeClassName={active}>
        Client Login
      </FadeLink>
    </div>
  );
}
