import React, { useEffect } from "react";
import Footer from "./footer";

import { wrapper, body } from "./layout.module.scss";

function setVH() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

export default function Layout({ children, noLogo }) {
  // fix for mobile address bar / dev tools
  useEffect(() => {
    setVH();

    window.addEventListener("resize", setVH);

    return () => {
      window.removeEventListener("reize", setVH);
    };
  });

  return (
    <div className={wrapper}>
      <div className={body}>{children}</div>
      <Footer noLogo={noLogo} />
    </div>
  );
}
