import React from "react";
import FadeLink from "./fadeLink";

import { logo, name } from "./logo.module.scss";

export default function Logo({ className }) {
  return (
    <FadeLink fade to="/" className={className}>
      <span className={logo}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
          width="32"
        >
          <defs />
          <path
            fill="#1E697D"
            fillRule="evenodd"
            stroke="#FFF"
            strokeWidth="2"
            d="M171 9c6 0 11 5 11 11v27c9-7 19-10 29-10 23 0 45 16 45 46 0 32-33 64-74 105v39c0 6-5 11-11 11H11c-6 0-11-5-11-11V20C0 14 5 9 11 9h160zm34 49c-5 0-15 2-23 12v90c28-29 51-52 51-73 0-20-15-29-28-29z"
          />
        </svg>
        <span className={name}>Fresh Brewed Design</span>
      </span>
    </FadeLink>
  );
}
